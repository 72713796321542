<template>
  <div id="placeholder-page">
    <div id="info-container">
      <h1>
        We are currently setting things up <br />
        Please wait for further announcements
      </h1>
      <span id="media-links">
        <a
          href="mailto:contact@vtubervalhalla.com"
          target="_blank"
          title="Contact us through E-mail"
        >
          <img src="..\\..\\assets\\Mail_White.svg" />
        </a>

        <a
          href="https://youtube.com/@vtubervalhalla?si=0u36Y-jxRg4DxvNp"
          target="_blank"
          title="Follow us on Youtube"
        >
          <img
            src="..\\..\\assets\\YT_Play_Button_White.png"
            alt="Youtube Link"
          />
        </a>

        <a
          href="https://bsky.app/profile/vtubervalhalla.bsky.social"
          target="_blank"
          title="Follow us on Bluesky"
        >
          <img
            src="..\\..\\assets\\Bluesky_Logo_White.png"
            alt="Bluesky Link"
          />
        </a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlaceholderPage",
  components: {},
  data() {
    return {};
  },
  computed: {},
};
</script>

<style scoped>
#placeholder-page {
  height: 100%;
  width: 100%;
  background-color: black;
  color: gold;
  display: flex;
}

#info-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  justify-content: center;
}

#media-links {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 30%;
  margin: 1% auto;
  height: 50px;
}

img {
  height: 50px;
  max-height: 50px;
}
</style>
