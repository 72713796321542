<template>
  <nav>
    <div id="leftNav">
      <RouterLink class="navBarLinks" to="/">
        <img
          src="../assets/logo.png"
          alt="Logo is missing"
          width="60"
          height="60"
          id="navLogo"
      /></RouterLink>
      <h2 id="siteTitle">VTUBER VALHALLA</h2>
    </div>

    <div id="rightNav">
      <RouterLink class="navBarLinks" to="/"><h3>Home</h3></RouterLink>
      <RouterLink class="navBarLinks" to="/info"><h3>Info</h3></RouterLink>
      <RouterLink class="navBarLinks" to="/talents"
        ><h3>Talents</h3></RouterLink
      >
    </div>
  </nav>
</template>

<script>
export default {
  name: "HeaderNavBar",
  props: {},
  mounted() {},
  computed: {},
  watch: {
    handler(newVal) {
      console.log(newVal);
    },
  },
};
</script>

<style scoped>
nav {
  height: 10%;
  width: 100%;
  display: flex;
  background-color: whitesmoke;
  color: black;
  justify-content: space-evenly;
}

#leftNav {
  display: flex;
  width: 25%;
}

#rightNav {
  display: flex;
  width: 75%;
  justify-content: end;
}

#navLogo {
  margin: auto 5%;
}
#siteTitle {
  margin: auto;
}

.navBarLinks {
  margin: auto 2%;
  color: black;
}

@media only screen and (max-width: 425px) {
  #siteTitle {
    display: none;
  }
}
</style>
