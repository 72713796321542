<template>
  <div id="infoPage"></div>
</template>

<script>
export default {
  name: "InfoPage",
  props: {},
  mounted() {},
};
</script>

<style scoped>
#infoPage {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
</style>
