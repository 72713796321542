<template>
  <div id="mainPage"></div>
</template>

<script>
export default {
  name: "MainPage",
  props: {},
};
</script>

<style scoped>
#mainPage {
  height: 100vh;
  width: 100vw;
  display: flex;
  /* background-color: skyblue; */
}

/* @media only screen and (min-width: 426px) {
  #mainPage {
    border-radius: 50px 50px 0 0;
  }
} */
</style>
