import { createWebHistory, createRouter } from "vue-router";

import MainPage from "@/pages/main/MainPage.vue";
import InfoPage from "@/pages/info/InfoPage.vue";
import TalentPage from "@/pages/talents/TalentPage.vue";

const routes = [
  { path: "/", component: MainPage },
  { path: "/info", component: InfoPage },
  { path: "/talents", component: TalentPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default {
  router,
};
