<template>
  <div id="talentPage"><TalentGrid /></div>
</template>

<script>
import TalentGrid from "@/components/talent/TalentGrid.vue";
export default {
  components: {
    TalentGrid,
  },
};
</script>

<style scoped>
#talentPage {
  height: 100vh;
  width: 100vw;
  display: flex;
}
</style>
