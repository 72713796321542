<template>
  <div id="talentGridContainer">
    <div class="talentCell" v-for="talent in get_talents" :key="talent">
      Hello
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      talents: [],
    };
  },
  mounted() {},
  methods: {},
  computed: {
    get_talents: function () {
      return this.talents;
    },
  },
};
</script>

<style scoped></style>
