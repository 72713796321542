<template>
  <div id="siteContent">
    <HeaderNavBar />
    <main>
      <RouterView />
    </main>
  </div>
</template>

<script>
import HeaderNavBar from "@/components/HeaderNavBar.vue";

export default {
  name: "RouterPage",
  components: { HeaderNavBar },
};
</script>

<style>
#siteContent,
main {
  width: 100%;
  height: 100vh;
}

@media only screen and (min-width: 426px) {
  main > * {
    background-color: skyblue;
    border-radius: 50px 50px 0 0;
  }
}
</style>
